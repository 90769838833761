<template>
  <a-modal
    width="600px"
    class="add-manual-enumerators-modal"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :visible="true"
    :closable="false">
    <h3 class="flex align-center txt-40 txt-bold txt-font-din-medium">
      {{ $t('components.titles.manualEnumerator') }}
    </h3>
    <p>{{ $t('components.description.enterAllRequiredInformation') }}</p>
    <a-form layout="vertical" :form="form" :hide-required-mark="true">
      <a-row :gutter="20">
        <a-col :lg="12" :md="24">
          <a-form-item :label="$t('components.labels.firstName')">
            <a-input v-decorator="[
                'firstName',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('components.description.pleaseEnterTheFirstName'),
                      transform: (value) => value && value.trim()
                    },
                  ],
                },
              ]" :disabled="saving" size="large"/>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="24">
          <a-form-item :label="$t('components.labels.familyName')">
            <a-input v-decorator="[
                'familyName',
                {
                  rules: [
                    {
                      required: true,
                      message: $t('components.description.pleaseEnterTheFamilyName'),
                      transform: (value) => value && value.trim(),
                    },
                  ],
                },
              ]" :disabled="saving" size="large"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="20">
        <a-col :lg="12" :md="24">
          <a-form-item :label="$t('components.description.gender')">
            <a-select v-decorator="[
              'gender',
              {
                rules: [
                  {
                    required: true,
                    message: $t('components.description.pleaseSelectAGender')
                  },
                ],
              },
            ]"
             :disabled="saving"
            :get-popup-container="(triggerNode) => triggerNode.parentNode">
              <a-icon slot="suffixIcon" type="caret-down"/>
              <a-select-option
                value="female">
                {{ $tc('values.female', 1) }}
              </a-select-option>
              <a-select-option
                value="male">
                {{ $tc('values.male', 1) }}
              </a-select-option>
              <a-select-option
                value="unknown">
                {{ $t('components.labels.preferNotToDisclose') }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="24">
          <a-form-item :label="$t('components.labels.roleInPreviousSurvey')">
            <a-select v-decorator="[
              'role',
              {
                rules: [
                  {
                    required: false
                  },
                ],
              },
            ]"
            :disabled="saving"
            :get-popup-container="(triggerNode) => triggerNode.parentNode">
              <a-icon slot="suffixIcon" type="caret-down"/>
              <a-select-option
                value="not applicable">
                {{ $t('components.dropdown.notApplicable') }}
              </a-select-option>
              <a-select-option
                value="field supervisor">
                {{ $t('components.dropdown.fieldSupervisor') }}
              </a-select-option>
              <a-select-option
                value="team leader">
                {{ $t('components.dropdown.teamLeader') }}
              </a-select-option>
              <a-select-option
                value="measurer">
                {{ $t('components.dropdown.measurer') }}
              </a-select-option>
              <a-select-option
                value="assistant measurer">
                {{ $t('components.dropdown.assistantMeasurer') }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="20">
        <a-col :lg="24" :md="24">
          <a-form-item :label="$t('components.titles.language')">
            <a-input v-decorator="[
                'language',
                {
                  rules: [
                    {
                      required: false,
                      transform: (value) => value && value.trim()
                    },
                  ],
                },
              ]" :disabled="saving" size="large"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-alert
        v-if="hasSupervisor"
        class="mb-20"
        :description="$t('components.description.theSupervisorRoleHasAlreadyBeenAssigned')"
        type="info"
        show-icon/>
      <a-form-item>
        <span slot="label" class="flex align-center">
          {{ $t('components.labels.isThePersonAssignedSupervisor') }}&nbsp;&nbsp;
          <a-tooltip
            :title="$t('components.toolTips.theSupervisorRoleIsReserved')"
            class="ml-8">
            <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
          </a-tooltip>
        </span>
        <a-radio-group v-decorator="[
          'supervisor',
          {
            initialValue: false,
            rules: [
              {
                required: true,
                message: $t('components.description.pleasePickEnumeratorSupervisor')
              },
            ],
          },
        ]" :disabled="hasSupervisor || saving">
            <a-radio :value="true">
              {{ $t('values.yes') }}
            </a-radio>
            <a-radio :value="false">
              {{ $t('values.no') }}
            </a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
    <div class="flex justify-space-between w100">
      <a-button
        class="mt-24"
        type="primary" ghost
        size="large"
        :disabled="saving"
        @click="onExit">
        {{ $t('values.cancel') }}
      </a-button>
      <div>
        <a-button
          class="mt-24 mr-10 save-and-add-new"
          type="primary"
          size="large"
          :disabled="saving"
          :loading="saving && !isSaveAndExit"
          :title="$t('components.description.saveAndAddNewEnumerator')"
          @click="save(true)">
          {{ $t('components.description.saveAndAddNewEnumerator') }}
        </a-button>
        <a-button
          class="mt-24 save-and-exit"
          type="primary"
          size="large"
          :disabled="saving"
          :title="$t('components.description.saveAndExit')"
          :loading="saving && isSaveAndExit"
          @click="save(false)">
          {{ $t('components.description.saveAndExit') }}
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { requestData } from '../../../../store/modules/request';

export default {
  name: 'AddManualEnumeratorsModal',
  props: {
    project: {
      type: Object,
      required: true
    },
    groupId: {
      type: Number,
      required: true
    },
    onExit: {
      type: Function,
      required: true
    },
    onManualEnumerator: {
      type: Function,
      required: true
    },
    enumerator: {
      type: Object || undefined,
      default: undefined
    },
    hasSupervisor: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'manual_enumerator' }),
      saving: false,
      isSaveAndExit: false
    };
  },
  computed: {
    ...mapState({
      standardizationGroupMap: state => state.standardizationGroups.standardizationGroupMap
    }),
    ...requestData(['forms'])
  },
  methods: {
    ...mapActions([
      'addEnumeratorToGroup'
    ]),
    async save(addNew) {
      if (this.saving) {
        return;
      }
      this.saving = true;
      this.isSaveAndExit = !addNew;
      this.form.validateFields(async (err, values) => {
        if (!err) {
          try {
            const displayName = `${values.firstName} ${values.familyName}`;
            const newEnumerator = await this.addEnumeratorToGroup({
              groupId: this.groupId,
              data: { ...values, displayName }
            });

            const res = [];
            for (const [_, value] of Object.entries(this.standardizationGroupMap)) {
              res.push(...value.enumerators);
            }

            this.onManualEnumerator(addNew);
            if (addNew) {
              this.form.resetFields();
            }
          } catch (error) {
            this.$alert().danger('An error occured saving enumerator');
            this.saving = false;
          }
        }
        this.saving = false;
      });
    }
  }
};
</script>

<style scoped>
.save-and-add-new {
  max-width: 260px;
}
.save-and-exit {
  max-width: 145px;
}
</style>
