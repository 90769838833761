var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"add-manual-enumerators-modal",attrs:{"width":"600px","dialog-style":{ display: 'none' },"footer":null,"visible":true,"closable":false}},[_c('h3',{staticClass:"flex align-center txt-40 txt-bold txt-font-din-medium"},[_vm._v(" "+_vm._s(_vm.$t('components.titles.manualEnumerator'))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t('components.description.enterAllRequiredInformation')))]),_c('a-form',{attrs:{"layout":"vertical","form":_vm.form,"hide-required-mark":true}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"lg":12,"md":24}},[_c('a-form-item',{attrs:{"label":_vm.$t('components.labels.firstName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'firstName',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.$t('components.description.pleaseEnterTheFirstName'),
                    transform: function (value) { return value && value.trim(); }
                  } ],
              } ]),expression:"[\n              'firstName',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: $t('components.description.pleaseEnterTheFirstName'),\n                    transform: (value) => value && value.trim()\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.saving,"size":"large"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":24}},[_c('a-form-item',{attrs:{"label":_vm.$t('components.labels.familyName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'familyName',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.$t('components.description.pleaseEnterTheFamilyName'),
                    transform: function (value) { return value && value.trim(); },
                  } ],
              } ]),expression:"[\n              'familyName',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: $t('components.description.pleaseEnterTheFamilyName'),\n                    transform: (value) => value && value.trim(),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.saving,"size":"large"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"lg":12,"md":24}},[_c('a-form-item',{attrs:{"label":_vm.$t('components.description.gender')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'gender',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('components.description.pleaseSelectAGender')
                } ],
            } ]),expression:"[\n            'gender',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('components.description.pleaseSelectAGender')\n                },\n              ],\n            },\n          ]"}],attrs:{"disabled":_vm.saving,"get-popup-container":function (triggerNode) { return triggerNode.parentNode; }}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_c('a-select-option',{attrs:{"value":"female"}},[_vm._v(" "+_vm._s(_vm.$tc('values.female', 1))+" ")]),_c('a-select-option',{attrs:{"value":"male"}},[_vm._v(" "+_vm._s(_vm.$tc('values.male', 1))+" ")]),_c('a-select-option',{attrs:{"value":"unknown"}},[_vm._v(" "+_vm._s(_vm.$t('components.labels.preferNotToDisclose'))+" ")])],1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":24}},[_c('a-form-item',{attrs:{"label":_vm.$t('components.labels.roleInPreviousSurvey')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'role',
            {
              rules: [
                {
                  required: false
                } ],
            } ]),expression:"[\n            'role',\n            {\n              rules: [\n                {\n                  required: false\n                },\n              ],\n            },\n          ]"}],attrs:{"disabled":_vm.saving,"get-popup-container":function (triggerNode) { return triggerNode.parentNode; }}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_c('a-select-option',{attrs:{"value":"not applicable"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.notApplicable'))+" ")]),_c('a-select-option',{attrs:{"value":"field supervisor"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.fieldSupervisor'))+" ")]),_c('a-select-option',{attrs:{"value":"team leader"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.teamLeader'))+" ")]),_c('a-select-option',{attrs:{"value":"measurer"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.measurer'))+" ")]),_c('a-select-option',{attrs:{"value":"assistant measurer"}},[_vm._v(" "+_vm._s(_vm.$t('components.dropdown.assistantMeasurer'))+" ")])],1)],1)],1)],1),_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"lg":24,"md":24}},[_c('a-form-item',{attrs:{"label":_vm.$t('components.titles.language')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'language',
              {
                rules: [
                  {
                    required: false,
                    transform: function (value) { return value && value.trim(); }
                  } ],
              } ]),expression:"[\n              'language',\n              {\n                rules: [\n                  {\n                    required: false,\n                    transform: (value) => value && value.trim()\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.saving,"size":"large"}})],1)],1)],1),(_vm.hasSupervisor)?_c('a-alert',{staticClass:"mb-20",attrs:{"description":_vm.$t('components.description.theSupervisorRoleHasAlreadyBeenAssigned'),"type":"info","show-icon":""}}):_vm._e(),_c('a-form-item',[_c('span',{staticClass:"flex align-center",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('components.labels.isThePersonAssignedSupervisor'))+" "),_c('a-tooltip',{staticClass:"ml-8",attrs:{"title":_vm.$t('components.toolTips.theSupervisorRoleIsReserved')}},[_c('a-icon',{staticStyle:{"color":"#c6c7c6","font-size":"20px"},attrs:{"type":"info-circle"}})],1)],1),_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'supervisor',
        {
          initialValue: false,
          rules: [
            {
              required: true,
              message: _vm.$t('components.description.pleasePickEnumeratorSupervisor')
            } ],
        } ]),expression:"[\n        'supervisor',\n        {\n          initialValue: false,\n          rules: [\n            {\n              required: true,\n              message: $t('components.description.pleasePickEnumeratorSupervisor')\n            },\n          ],\n        },\n      ]"}],attrs:{"disabled":_vm.hasSupervisor || _vm.saving}},[_c('a-radio',{attrs:{"value":true}},[_vm._v(" "+_vm._s(_vm.$t('values.yes'))+" ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v(" "+_vm._s(_vm.$t('values.no'))+" ")])],1)],1)],1),_c('div',{staticClass:"flex justify-space-between w100"},[_c('a-button',{staticClass:"mt-24",attrs:{"type":"primary","ghost":"","size":"large","disabled":_vm.saving},on:{"click":_vm.onExit}},[_vm._v(" "+_vm._s(_vm.$t('values.cancel'))+" ")]),_c('div',[_c('a-button',{staticClass:"mt-24 mr-10 save-and-add-new",attrs:{"type":"primary","size":"large","disabled":_vm.saving,"loading":_vm.saving && !_vm.isSaveAndExit,"title":_vm.$t('components.description.saveAndAddNewEnumerator')},on:{"click":function($event){return _vm.save(true)}}},[_vm._v(" "+_vm._s(_vm.$t('components.description.saveAndAddNewEnumerator'))+" ")]),_c('a-button',{staticClass:"mt-24 save-and-exit",attrs:{"type":"primary","size":"large","disabled":_vm.saving,"title":_vm.$t('components.description.saveAndExit'),"loading":_vm.saving && _vm.isSaveAndExit},on:{"click":function($event){return _vm.save(false)}}},[_vm._v(" "+_vm._s(_vm.$t('components.description.saveAndExit'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }